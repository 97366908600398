<template>
  <div id="loadMoreSelect" style="flex: 1">
    <el-select
      @focus="focusMethod"
      v-model="bankOrigin"
      placeholder="请选择"
      v-validate="'required'"
      clearable
      name="project"
      style="width: 100%"
      filterable
      remote
      :remote-method="remoteMethod"
      :popper-append-to-body="false"
      @change="changeValue"
    >
      <el-option v-for="item in bankOriginList" :key="item.id" :label="item.bank_name" :value="item.bank_yeath_code"></el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  watch: {
    value: {
      handler(val) {
        this.bankOrigin = val;
        this.$emit('input', val);
      },
      immediate: true,
    },
  },
  data() {
    return {
      bankOrigin: null,
      bankOriginList: [],
      getAll: false,
      currentPage: 1,
    };
  },
  created() {
    this.searchData();
  },
  methods: {
    remoteMethod(query) {
      let that = this;
      if (query !== this.query) {
        const loadSelectElement = document.getElementById('loadMoreSelect').getElementsByClassName('el-select-dropdown__wrap')[0];
        loadSelectElement.scrollTop = 0;
        this.query = query;
        this.currentPage = 1;
      }
      //如果搜索框是空的，就访问原始数据，否则从后台搜索
      that.searchData(query);
    },
    searchData(query) {
      this.$axios
        .post(this.$api.yiyun.bankCodeList, {
          bank_name: query,
          page: this.currentPage,
          rows: 15,
        })
        .then(res => {
          this.loading = false;
          if (this.currentPage == 1) {
            this.bankOriginList = res.result.list;
          } else {
            this.bankOriginList = this.bankOriginList.concat(...res.result.list);
          }
          if (res.result.list.length < 15) {
            this.getAll = true;
          }
        });
    },
    scrollLoadData() {
      const loadSelectElement = document.getElementById('loadMoreSelect').getElementsByClassName('el-select-dropdown__wrap')[0];
      const that = this;
      // 当滚动条高度等于卷入高度+可视高度时，说明到达底部，scrollHeight :滚动条高度，scrollTop ：已卷入不可见的滚动条高度，clientHeight：可见的滚动条高度。
      const scrollDistance = loadSelectElement.scrollHeight - loadSelectElement.scrollTop - loadSelectElement.clientHeight;
      //如果已加载完全部数据，取消监听
      if (that.getAll) {
        loadSelectElement.removeEventListener('scroll', this.scrollLoadData);
        return;
      }
      // 非搜索
      if (scrollDistance <= 0) {
        //页码依次加1
        that.currentPage++;
        that.searchData(that.query);
      }
    },
    //给select框加滚动监测
    setLoadMore() {
      //获得元素
      const loadSelectElement = document.getElementById('loadMoreSelect').getElementsByClassName('el-select-dropdown__wrap')[0];
      loadSelectElement.addEventListener('scroll', this.scrollLoadData);
    },
    // 聚焦监听下拉
    focusMethod() {
      let that = this;
      this.$nextTick(() => {
        that.setLoadMore();
      });
    },
    // 失焦取消监听
    blurMethod() {
      const loadSelectElement = document.getElementById('loadMoreSelect').getElementsByClassName('el-select-dropdown__wrap')[0];
      loadSelectElement.removeEventListener('scroll', this.scrollLoadData);
    },
    changeValue() {
      this.$emit('input', this.bankOrigin);
      let data = this.bankOriginList.find(i => i.bank_yeath_code === this.bankOrigin);
      this.$emit('change', data || {});
    },
  },
};
</script>

<style></style>
